// @import "../global/variables.scss";
// MOBILE VERSION: add no scroll

.header-logo {
    width: 45px;
}

// .dropdown:hover .dropdown-menu {
//     display: block;
//     margin-top: 0px;
// }
.navbar {
    .container-fluid {
        padding: 0;
    }
}

#navbarScroll {
    align-self: flex-end;
    padding-left: 6rem;
    padding-right: 8rem;
    font-size: 14pt;
}

.nav-link {
    border-top: 4px solid black;
    padding: 0;
    color: black !important;
    font-weight: 900;
    padding: 0 0.1em !important;
    width: 115px;
    border-radius: 2px;
    cursor: pointer;
}

.active {
    border-color: $blue;
    color: $blue !important;
}

.navbar {
    padding-top: 2rem;
}

.navbar-nav {
    justify-content: space-between;
    width: 100%;
}

.dropdown-menu {
    font-weight: bold;
    border: none;
    background-color: #f9f9f9;

    li {
        padding: 10px;
    }

    a {
        color: $black
    }
}

// Search
.show-search-button {
    color: $black;
    background: transparent;
    border: none;
    position: absolute;
    right: 0;
}

.search-bar {
    transition: all 0.3s ease-in-out;
    margin-left: auto;

    .form-group {
        width: 400px;
    }

    &.hidden {
        max-height: 0;
        opacity: 0;
    }

    &.show {
        max-height: 50px;
        opacity: 1;
        margin-top: 25px;
    }
}

.page-header {
    margin-bottom: 30px;

    .page-category {
        font-weight: 900;
        border-bottom: 5px solid;
        position: relative;
        display: inline-block;
        border-radius: 2px;
        margin-bottom: 25px;
        margin-top: 50px;
    }
    
    .page-title {
        font-weight: 900;
    }
}

.contact-overlay {
    position: fixed;
    top: 0;
    bottom: 50%;
    left: 0;
    background-color: white;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: center;
    z-index: 1;
}

.contact-overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

@media (max-width: 992px) {
    .navbar-collapse {
        background-color: $white;
        position: fixed;
        top: 120px;
        left: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        // width: 75%;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .navbar-collapse.collapsing {
        // left: -75%;
        left: -100%;
        height: 100vh;
        transition: height 0s ease;
        // transition: left 300ms ease-in-out;
    }

    .navbar-collapse.show {
        // position: fixed;
        left: 0;
        transition: left 400ms ease-in-out;
    }

    .navbar-collapse.mshele {
        // position: fixed;
        left: 0;
        transition: left 400ms ease-in-out;
    }

    .navbar-toggler.collapsed ~ .navbar-collapse {
        // position: relative;
        transition: left 400ms ease-in-out;
    }

    .nav-link {
        width: 100%;
        border: none;
        margin: 10px 0;
        text-align: center;

        &.show {
            color: $blue !important;
        }
    }

    .dropdown {
        .nav-link::after {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
        }

    }

    .dropdown-menu.show {
        text-align: center;
        border: none;
    }

    .language-chooser {
        position: relative;
        top: 0;
        right: 0;
        text-align: center;

        .language-chooser-button {
            margin-left: 0;
            margin-bottom: 10px;
        }
    }

    .search-bar.show {
        margin-right: auto;
    }
}

.desktop-navbar {
    display: none;
}

@media (min-width: 991px) {
    .desktop-navbar {
        display: flex;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
     }    
}