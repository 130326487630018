
.checkbox {
    margin-bottom: 25px;

    /* The container */
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        // font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        // background-color: #eee;
        border: 2px solid $pink;
        border-radius: 5px;
    }

    /* On mouse-over, add a grey background color */
    // .container:hover input ~ .checkmark {
    //     background-color: #ccc;
    // }

    /* When the checkbox is checked, add a blue background */
    // .container input:checked ~ .checkmark {
    //     background-color: #2196f3;
    // }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 4px;
        top: 0px;
        width: 8px;
        height: 13px;
        border: solid #000000;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
