.home-section {
    border-radius: 10px;
    border: 4px solid transparent;
    padding: 10px;
    margin-bottom: 25px;
}

.home-section:hover {
    // cursor: pointer;
    border: 4px solid;

    &.home-section-blue {
        // border-color: $blue;
        border-color: #1e53d6;
    }

    &.home-section-pink {
        border-color: $pink;
    }

    &.home-section-yellow {
        border-color: $yellow;
    }

    &.home-section-temp,
    &.home-section-grey {
        border-color: $grey;
    }
}

.home-section-blue {
    .home-section-inner {
        // background-color: $blue;
        background-color: #1e53d6;
    }
}

.home-section-pink {
    .home-section-inner {
        background-color: $pink;
    }
}

.home-section-yellow {
    .home-section-inner {
        background-color: $yellow;
    }
}

.home-section-temp,
.home-section-grey {
    .home-section-inner {
        background-color: $grey;
    }
}

.home-section-yellow,
.home-section-grey {
    .col-12.col-sm-7 {
        text-align: right;
    }

    .home-section-title {
        margin-right: -10px;

        &::after {
            width: 108% !important;
            margin-left: -9px !important;
        }
    }
}

.home-section-inner {
    border-radius: 10px;
    margin: 0;
    padding: 10px;

    .home-section-title {
        display: inline-block;
        font-size: 46px;
        font-weight: 900;

        &::after {
            content: "";
            border-bottom: 7px solid #000;
            display: block;
            width: 113%;
            margin-left: -25px;
        }
    }

    .home-section-subtitle {
        font-size: 40px;
        font-weight: 900;
        margin-top: 55px;
        margin-bottom: 25px;
    }

    .home-section-link {
        color: $black;
        font-weight: 600;
    }

    .col-12.col-sm-5 {
        margin-bottom: 25px;
    }

    .home-image {
        padding: 20px 40px 20px 0;
    }

    .home-image-right {
        padding: 20px 0 20px 40px;
    }

    .home-section-image {
        width: 100%;
        height: auto;
        min-height: 300px;
        border-radius: 5px;
    }
}
