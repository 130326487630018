@import "~bootstrap/scss/bootstrap";
@import "./global/general.scss";
@import "./components/loader.scss";
@import "./components/input.scss";
@import "./components/checkbox.scss";
@import "./components/radiobutton.scss";
@import "./components/button.scss";
@import "./components/header.scss";
@import "./components/footer.scss";
@import "./components/posts.scss";
@import "./components/home.scss";
@import "./components/faq.scss";

