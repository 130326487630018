$black: #000000;
$white: #ffffff;
$blue: #0430ba;
$pink: #ef377a;
$grey: #A9A9A9;
$lightgrey: #cccccc;

$mobile: 576px;

$DarkBrown: #292321;

$Orange: #CC3300;