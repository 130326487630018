.featured-image {
    height: 100%;
    min-height: 165px;
    max-height: 265px;
    object-fit: cover;
}

.latest-post {
    margin: 25px 0 55px;

    h1 {
        color: $black;
    }

    .post-date {
        margin: 25px 0;
    }
}

.posts-list {
    margin-bottom: 45px;

    .post-item {
        margin-bottom: 25px;
    }

    .posts-image {
        height: 100%;
        min-height: 70px;
        max-height: 165px;
        object-fit: cover;
        margin-bottom: 15px;
    }

    h5 {
        color: $black;
    }

    .post-date {
        margin: 10px 0 15px;
    }
}

.search-item {
    margin-bottom: 25px;
    a {
        color: $black;
        font-weight: bold;
    }
}

.post-featured-image {
    height: 50vh;
    object-fit: cover;
}

.share-section {
    margin: 3rem 0;
}

.share {
    font-weight: 300;
    display: inline-block;
    margin-right: 15px;
}

.related-post-item {
    margin: 1.5rem 0;

    h5 {
        color: $black;
    }

    .related-post-image {
        height: 160px;
        object-fit: cover;
    }
}

.author-section {
    margin: 2.5rem 0 0;

    .author-name {
        font-weight: 600;
    }

    .author-image {
        height: 50px;
        object-fit: contain;
    }
}

.exhibition-date {
    font-weight: 400;
}

@media (max-width: 992px) {
    .posts-list {   
        padding: 0 10px;
    }

    .author-section {    
        .author-image {
            height: 100px;
            object-fit: contain;
        }
    }
}