.faq-section {
    margin-bottom: 3rem;

    h4 {
        margin-bottom: 1rem;
    }
}

.accordion-button {
    padding: 1.25rem;
}

.accordion-button::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath d='M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z'/%3E%3C/svg%3E");
    width: 1.35rem;
    height: 1.35rem;
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 448 512' style='enable-background:new 0 0 448 512;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M413.1,222.5l22.2,22.2c9.4,9.4,9.4,24.6,0,33.9L241,473c-9.4,9.4-24.6,9.4-33.9,0L12.7,278.6 c-9.4-9.4-9.4-24.6,0-33.9l22.2-22.2c9.5-9.5,25-9.3,34.3,0.4L184,343.4V56c0-13.3,10.7-24,24-24h32c13.3,0,24,10.7,24,24v287.4 l114.8-120.5C388.1,213.1,403.6,212.9,413.1,222.5L413.1,222.5z'/%3E%3C/svg%3E%0A");
}

.accordion-item {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
}

.accordion-button:not(.collapsed) {
    color: $white;
    background-color: $black;
}

.accordion-collapse.collapse {
    border-bottom: 1px solid $black;
    // transition: all 0.7s ease-in-out;
}
