// ADD HOVER TO BUTTON

.btn-search {
    color: $blue;
    border: none;
    border-radius: 5px;
    position: absolute;
    top: 4px;
    right: 3px;
}

.btn-subscribe {
    background-color: #262626;
    border: none;
    border-radius: 5px; 
    color: $white;
    position: absolute;
    top: 6px;
    right: 4px;   
}

.btn-blue {
    color: $white;
    background-color: $blue;
    border-color: $blue;
}

.btn-blue:hover {
    color: $white;
    background-color: #032896;
    border-color: #032896;
}