.wpcf7-form-control.wpcf7-radio {
    position: relative;
    margin-top: 5px;
    display: block;
}

/* Container */
.wpcf7-list-item {
    display: block;
    margin-left: 10px;

    [type="radio"] {
        accent-color: $pink;
        margin-right: 5px;
    }
}
