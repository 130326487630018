.outlined-radiobutton {
    margin-bottom: 45px;

    .input-label {
        display: block;
        font-size: 1rem;
        background-color: $white;
        color: $black;
        padding: 0;
        margin: 0;
        transition: 0.1s ease-out;
        transform-origin: left top;
        pointer-events: none;
        font-weight: bold;
    }

}

.outlined-textfield {
    position: relative;

    .input-label {
        position: absolute;
        font-size: 1rem;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $white;
        color: $black;
        padding: 0 0.3rem;
        margin: 0 0.5rem;
        transition: 0.1s ease-out;
        transform-origin: left top;
        pointer-events: none;
        font-weight: bold;
    }

    .input-border {
        font-size: 1rem;
        border: none;
        border-width: 0px;
        border-bottom: 3px solid $pink;
        border-radius: 11px;
        background-color: transparent;
        padding: 10px 15px 5px;
        width: 100%;
        color: $black;
        transition: 0.1s ease-out;
        outline-color: lightgrey;
    }

    .textarea-border {
        border-top: 3px solid $pink;
        border-radius: 11px;
    }

    .textarea-label {
        transform: translateY(-200%);
    }

    .input-border:focus {
        border-color: $blue;
        outline-color: lightgrey;
    }

    .input-border:focus + .input-label {
        color: $black;
        top: 0;
        transform: translateY(-50%) scale(0.9);
        outline-color: lightgrey;
    }
    
    .input-border:not(:placeholder-shown) + .input-label {
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }

    .input-border:focus-visible {
        outline-color: lightgrey;
    }

    .file-label {
        font-size: 1rem;
        color: $black;
        padding: 0 0.3rem;
        margin: 0 0.5rem;
        font-weight: bold;
    }

    input.wpcf7-file::-webkit-file-upload-button {
        margin-top: 10px;
        margin-left: 10px;
        border: none;
        color: #fff;
        font-weight: 500;
        background: $pink;
        padding: 4px 18px;
        border-radius: 5px;
        cursor: pointer;
    }

    input.wpcf7-file::-webkit-file-upload-button:hover {
        background: #ec1363;
    }

    input.wpcf7-file::-webkit-file-upload-button:focus{
        outline:none;
    }

    input.wpcf7-file:focus {
        outline: none;
    }
}

.form-check-input:checked {
    background-color: $pink;
    border-color: $pink;
}

.document-section {
    margin-bottom: 45px;

    .outlined-textfield {
        margin-bottom: 5px !important;
    }

    p {
        font-size: 12px;
    }
}

.outlined-textfield.subscribe {
    .input-label {
        background-color: $black;
        color: $white;
    }

    .input-border {
        color: $white;
    }

    .input-border:focus + .input-label {
        color: $white;//$pink;
    }  
    
    .input-border:focus {
        border-color: $pink;
    }
}

.outlined-textfield.search {
    .input-border {
        border-color: $blue;
        width: 350px;
    }
}

.row {
    .outlined-textfield {
        margin-bottom: 45px;
    }
}

// .contact-form {
//     .row {
//         .col-12:first-child {
//             margin-right: 30px
//         }
//     }
// }

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}
