@keyframes ldio-di1781f60h {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-di1781f60h div {
  position: absolute;
  width: 58px;
  height: 58px;
  border: 10px solid $blue;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-di1781f60h div {
  animation: ldio-di1781f60h 1s linear infinite;
  top: 50px;
  left: 50px
}
.loadingio-spinner-rolling-r4ica76o3a {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  background: #ffffff;
}
.ldio-di1781f60h {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-di1781f60h div { box-sizing: content-box; }
/* generated by https://loading.io/ */
