@import "./variables.scss";

body {
    font-family: Arial, Helvetica, sans-serif;
    background-color: $white;
}

body:has(.contact-overlay) {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 900;
}

.vertical-center {
    min-height: 100%; /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh; /* These two lines are counted as one :-)       */

    display: flex;
    align-items: center;
}

a {
    text-decoration: none;
    color: $blue;
}

img {
    width: 100%;
    height: 100%;
}

.container {
    margin-bottom: 50px;
    // padding-right: var(--bs-gutter-x, 6.75rem);
    // padding-left: var(--bs-gutter-x, 6.75rem);
}

.form-group {
    position: relative;
}

.language-chooser {
    position: absolute;
    right: 0;
    top: 1.77rem;
    z-index: 1;

    .language-chooser-button {
        background: transparent;
        border: none;
        margin-left: 20px;
    }
}

.link-underline {
    // color: red;
    position: relative;
    text-decoration: none;
    display: inline-block;
}

.link-underline:after {
    display: block;
    content: "";
    border-bottom: solid 3px $black;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 100% 50%;
}

.link-underline:hover:after {
    transform: scaleX(1);
    transform-origin: 0 50%;
}

.exhibition-section .link-underline {
    color: $black;
    font-weight: 600;
}

.prev-link {
    text-align: right;
}

.location-address,
.location-hours,
.location-admission {
    p {
        margin-bottom: 0.3rem;
    }
}

.location-map {
    width: 100%;
    height: 315px;
    position: relative;
    margin-top: 40px;
    border-radius: 10px;
}

// programmes
.programmes {
    text-align: center;

    a {
        color: $black;
    }

    h3 {
        font-weight: bold;
        margin: 15px 0;
    }
}

@media (max-width: 993px) {
    .container {
        padding-right: var(--bs-gutter-x, 1.75rem);
        padding-left: var(--bs-gutter-x, 1.75rem);
    }
}

@media (min-width: 993px) {
    .container {
        padding-right: var(--bs-gutter-x, 4.75rem);
        padding-left: var(--bs-gutter-x, 4.75rem);
    }
}


.loader {
    min-height: 100vh
}