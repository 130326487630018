// @import "../global/variables.scss";

.footer {
    padding: 80px 0 20px; //250px;
    background-color: $black;
    color: $white;
    font-weight: bolder;

    a {
        color: $white;
        text-decoration: none;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    .footer-logo {
        width: 30px;
        margin: 25px 0;
    }

    .copyright-text {
        font-size: 12px;
        font-weight: normal;
    }

    .social-media {
        font-size: 24px;
        font-weight: normal;
        margin-bottom: 15px;

        a {
            margin-right: 15px;
        }
    }

    .footer-links {
        text-align: right;
    }

    .subscribe {
        position: relative;
    }

    .subscribe-message {
        color: $white;
        margin-top: 10px;
    }

    .active {
        color: $white !important;
    }

    .link-underline:after {
        border-bottom: solid 3px $white;
    }    

    @media (max-width: $mobile) {
        text-align: center;

        .footer-links {
            text-align: center;
        }

        .col-12 {
            margin-bottom: 20px;
        }
    }
}
